import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  useNavigate,
  Route,
  Routes,
} from "react-router-dom";
import Phase2 from "./pages/Phase2";
import Phase1 from "./pages/Phase1";
import Services from "./pages/Services";
import Pricing from "./pages/Pricing";
import FAQs from "./pages/FAQs";
import StratCall from "./pages/StratCall";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import AboutPH from "./pages/AboutPH";
import Career from "./pages/Career";
import Apply from "./pages/Apply";
import Blog1 from "./components-PH/Blog/Blogs/Blog1";
import Blog2 from "./components-PH/Blog/Blogs/Blog2";
import Blog3 from "./components-PH/Blog/Blogs/Blog3";
import Industry from "./components/Industry/Industry";
import AdminService from "./components/Industry/Services/AdminService";
import CustomerService from "./components/Industry/Services/CustomerService";
import MarketingService from "./components/Industry/Services/MarketingService";
import InsideSalesAgent from "./components/Industry/Services/InsideSalesAgent";
import SalesDevelopment from "./components/Industry/Services/SalesDevelopment";
import TransactionCoordinator from "./components/Industry/Services/TransactionCoordinator";
import ExecutiveVA from "./components/Industry/Services/ExecutiveVA";
import EngineeringSupport from "./components/Industry/Services/EngineeringSupport";
import ITSupport from "./components/Industry/Services/ITSupport";
import PrivacyPolicy from "./components-PH/PrivacyPolicy/PrivacyPolicy"; // Updated import
import PrivacyPolicy2 from "./components/PrivacyPolicy/PrivacyPolicy2";
import getLocation from "./getLocation";

const InitialRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const determineRedirectPath = async () => {
      try {
        const location = await getLocation();
        console.log("Location data:", location);

        if (location && location.country_code2 === "PK") {
          console.log("Redirecting to /ph/home");
          navigate("/ph/home", { replace: true });
        } else {
          console.log("Redirecting to /home");
          navigate("/home", { replace: true });
        }
      } catch (err) {
        console.error("Error determining redirect path:", err);
        navigate("/home", { replace: true });
      }
    };

    determineRedirectPath();
  }, [navigate]);

  return <div>Loading...</div>;
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<InitialRedirect />} />
      <Route path="/home" element={<Phase2 />} />
      <Route path="/services" element={<Services />} />
      <Route path="/about" element={<About />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/FAQs" element={<FAQs />} />
      <Route path="/book" element={<StratCall />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/administrative-services" element={<AdminService />} />
      <Route path="/customer-services" element={<CustomerService />} />
      <Route path="/marketing-services" element={<MarketingService />} />
      <Route path="/inside-sales-agent" element={<InsideSalesAgent />} />
      <Route path="/sales-development" element={<SalesDevelopment />} />
      <Route path="/privacypolicy2" element={<PrivacyPolicy2 />} />
      <Route
        path="/transaction-coordinator"
        element={<TransactionCoordinator />}
      />
      <Route path="/executive-va" element={<ExecutiveVA />} />
      <Route path="/it-support" element={<ITSupport />} />
      <Route path="/engineering-support" element={<EngineeringSupport />} />
      <Route path="/industry" element={<Industry />} />
      <Route path="/ph/" element={<Navigate to="/ph/home" replace />} />
      <Route path="/ph/home" element={<Phase1 />} />
      <Route path="/ph/about" element={<AboutPH />} />
      <Route path="/ph/career" element={<Career />} />
      <Route path="/ph/apply" element={<Apply />} />
      <Route path="/ph/blogs/blog1" element={<Blog1 />} />
      <Route path="/ph/blogs/blog2" element={<Blog2 />} />
      <Route path="/ph/blogs/blog3" element={<Blog3 />} />
      <Route path="/ph/privacy-policy" element={<PrivacyPolicy />} />{" "}
      {/* New route for Privacy Policy */}
    </Routes>
  );
}

export default App;
