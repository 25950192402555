import React, { useState } from "react";
import LogoWName from "../../assets/cplogo.png";
import "./Footer.css";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
  FaPaperPlane,
  FaTimes,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Component for individual contact info
const ContactInfo = ({ icon, text }) => (
  <div className="contact-info d-flex flex-row">
    <span className="contactIcon">{icon}</span>
    <p>{text}</p>
  </div>
);

// Contact information data
const ContactInfos = [
  {
    icon: <FaMapMarkerAlt />,
    text: "Unit 218 Civic Prime Civic Drive, Filinvest Corporate City Muntinlupa City, Metro Manila Philippines",
  },
  {
    icon: <FaEnvelope />,
    text: "jobs@cyberpros.ph",
  },
  {
    icon: <FaPhone />,
    text: "+639455617642",
  },
];

// Navigation items data
const navItems = [
  {
    label: "Home",
    href: "/ph/home",
  },
  {
    label: "About",
    href: "/ph/about",
  },
  {
    label: "Career",
    href: "/ph/career",
  },
  {
    label: "Apply Now",
    action: "openModal", // Indicate this triggers the modal
  },
  {
    label: "Privacy Policy",
    href: "/ph/privacy-policy",
  },
];

const Footer = () => {
  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    toast
      .promise(axios.post("https://cyberpros.ph/api/contactUs.php", formData), {
        pending: "Sending your message...",
        success: "Message sent successfully!",
        error: "Error sending message! Please try again.",
      })
      .then(() => {
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
      });
  };

  const openIframeModal = () => setIframeModalOpen(true);
  const closeIframeModal = () => setIframeModalOpen(false);

  return (
    <>
      <ToastContainer />
      <footer className="bg-dark text-white py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <h5>GET IN TOUCH</h5>
              <p>Our Contact Details:</p>
              <p className="lil-text">
                Come join our team! We would love to hear from you.
              </p>
              <div className="d-flex flex-column mt-4">
                {ContactInfos.map((info, index) => (
                  <ContactInfo key={index} icon={info.icon} text={info.text} />
                ))}
              </div>

              <div className="navi mt-4">
  <ul className="list-unstyled">
    {navItems.map((item, index) =>
      item.action === "openModal" ? (
        <li key={index}>
          <NavLink
            to="#"
            className="nav-link"
            onClick={openIframeModal}
          >
            {item.label}
          </NavLink>
        </li>
      ) : (
        <li key={index}>
          <NavLink to={item.href} className="nav-link">
            {item.label}
          </NavLink>
        </li>
      )
    )}
  </ul>
</div>

            </div>
            <div className="col-md-6">
              <h5>Contact Us</h5>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="email">
                    Email:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="message">
                    Message:
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn angas px-3 rounded-pill mt-4"
                >
                  <FaPaperPlane className="text-light p-0 m-0 me-2" />
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <div className="last container d-flex flex-column flex-md-row justify-content-between align-items-center my-3">
        <img src={LogoWName} alt="CyberPros Logo" height={60} />
        <p className="mb-0">&copy; 2024 Cyber Pros. All rights reserved.</p>
      </div>

      {/* Iframe Modal */}
      {iframeModalOpen && (
        <div
          className="modal-overlay"
          style={{ zIndex: "1200" }}
          onClick={closeIframeModal}
        >
          <div
            className="modal-content iframe-modal"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-modal" onClick={closeIframeModal}>
              <FaTimes size={24} />
            </button>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf2LSq9FBc9d-olv7iu-Y2tUBogYAmgnmn3Z2NXATJ5lBN1Lg/viewform"
              width="100%"
              height="500px"
              frameBorder="0"
              title="Application Form"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
