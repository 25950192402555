import React from "react";
import { NavLink } from "react-router-dom";
import "./Welcome.css";

const Welcome = ({ openIframeModal }) => {
  return (
    <section id="welcome">
      <div className="container text-center">
        <div className="col-12">
          <h1>
            WELCOME TO <span className="highlight">CYBER PROS</span>
          </h1>
          <h2>
            START YOUR NEW <span className="bold">CAREER</span> WITH US!
          </h2>
          <NavLink
            to="#"
            className="btn rounded-pill px-5"
            onClick={openIframeModal} // Trigger the modal on click
          >
            APPLY NOW!
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
