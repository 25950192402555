import React, { useState } from "react";
import Navbar from "../components-PH/Navbar-Phase1/Navbar";
import Footer from "../components-PH/Footer/Footer";
import Welcome from "../components-PH/Phase1_Components/Welcome/Welcome";
import Who from "../components-PH/Phase1_Components/Who/Who";
import What1 from "../components-PH/Phase1_Components/What/What1";
import HowToApply from "../components-PH/HowToApply/HowToApply";
import BlogSection from "../components-PH/Blog/BlogSection";
import SysReq from "../components-PH/SystemRequirements/SysReq";
import { FaTimes } from "react-icons/fa";

const Phase1 = () => {
  const [iframeModalOpen, setIframeModalOpen] = useState(false);

  const openIframeModal = () => setIframeModalOpen(true);
  const closeIframeModal = () => setIframeModalOpen(false);

  return (
    <div>
      <Navbar openIframeModal={openIframeModal} />
	  <Welcome openIframeModal={openIframeModal} />
      <Who />
      <HowToApply />
      <BlogSection />
      <SysReq />
      <What1 />
      <Footer openIframeModal={openIframeModal} />
      {iframeModalOpen && (
        <div
          className="modal-overlay"
          style={{ zIndex: "1200" }}
          onClick={closeIframeModal}
        >
          <div
            className="modal-content iframe-modal"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-modal" onClick={closeIframeModal}>
              <FaTimes size={24} />
            </button>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf2LSq9FBc9d-olv7iu-Y2tUBogYAmgnmn3Z2NXATJ5lBN1Lg/viewform"
              width="100%"
              height="500px"
              frameBorder="0"
              title="Strategy Call Form"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default Phase1;
