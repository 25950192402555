import React, { useState } from "react";
import Navbar from "../components-PH/Navbar-Phase1/Navbar";
import Footer from "../components-PH/Footer/Footer";
import WelcomeCareer from "../components-PH/Career_Components/1-Section/WelcomeCareer";
import Why from "../components-PH/Career_Components/2-Section/Why";
import CompanyMV from "../components-PH/Career_Components/3-Section/CompanyMV";
import Looking from "../components-PH/Career_Components/4-Section/Looking";
import CompanyProfile from "../components-PH/Career_Components/5-Section/CompanyProfile";
import { FaTimes } from "react-icons/fa";

const Career = () => {
	const [iframeModalOpen, setIframeModalOpen] = useState(false);

const openIframeModal = () => setIframeModalOpen(true);
const closeIframeModal = () => setIframeModalOpen(false);

	return (
		<div>
			<Navbar />
			<WelcomeCareer />
			<Why />
			<CompanyMV />
			<Looking  openIframeModal={openIframeModal} />
			<CompanyProfile />
			<Footer />
			{iframeModalOpen && (
        <div
          className="modal-overlay"
          style={{ zIndex: "1200" }}
          onClick={closeIframeModal}
        >
          <div
            className="modal-content iframe-modal"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-modal" onClick={closeIframeModal}>
              <FaTimes size={24} />
            </button>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf2LSq9FBc9d-olv7iu-Y2tUBogYAmgnmn3Z2NXATJ5lBN1Lg/viewform"
              width="100%"
              height="500px"
              frameBorder="0"
              title="Strategy Call Form"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
		</div>
	);
};

export default Career;
